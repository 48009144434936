var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"mx-0 pa-0 fader-in-t",attrs:{"elevation":"0","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-list',{staticClass:"checkbox-action",class:{
                        'five-column':
                            _vm.getDisplayItems.filter((r) => r.isParent).length >
                            1,
                    },attrs:{"flat":"","loading":true}},_vm._l((_vm.getDisplayItems),function(item){return _c('v-list-item',{key:`group_${item.title}`},[(!item.isParent)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"name":item.title,"id":`check_${item.key}`,"ripple":false,"true-value":true,"false-value":false,"color":"secondary","hide-details":""},on:{"click":function($event){return _vm.setItemSelected(item)}},model:{value:(item.option),callback:function ($$v) {_vm.$set(item, "option", $$v)},expression:"item.option"}})],1):_vm._e(),_c('v-list-item-content',{staticClass:"text-start"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({class:`${
											item.isParent
												? 'body-2 text-wrap mt-2 font-weight-bold'
												: 'body-2 text-wrap mt-2'
										}`},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t( `${"report.v2."}${_vm.getType}.${ item.key }` ))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( `${"report.v2."}${_vm.getType}.${ item.key }` ))+" ")])])],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }