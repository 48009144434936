import { Component, Emit, Watch, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSelect from "@/components/Content/CardSelect.vue";
import PaginatedChips from "@/views/Admin/Reports/V2/Dependencies/Chips/PaginatedIndex.vue";
import DatePicker from "@/components/Content/DatePicker.vue";
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import {
	isAfterCompare,
	isMaxDays,
	isRequired,
} from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import { isNull } from "lodash";
import { ReportResourceDataEntity } from "@/models/Reports/v2/Report";
import { getPermisionReport } from "@/utils/permissionResolve";
import NotPermission from "@/views/Errors/not_permission.vue";
import { DATA_RANGE_CUSTOM } from "@/utils/report";
import { getFormattedDate } from "@/services/date-service";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";

@Component({
	components: {
		CardAutocomplete,
		CardSelect,
		PaginatedChips,
		DatePicker,
		DataPickerStarTime,
		NotPermission
	},
	mixins: [],
})
export default class ReportFilters extends Vue {
	/**
	 * PROPS
	 */

	/**
	 * GETTERS
	 */
	@Getter("report_v2/storeReport") getStoreReport!: ReportDataEntity;
	@Getter("report_v2/storeSchedule") getStoreSchedule!: ScheduleEntity;
	@Getter("report_v2/storeResources") getStoreResources!: ResourceDataEntity;
	@Getter("report_v2/storeResourceData")
	getStoreResourceData!: ReportResourceDataEntity;
	@Getter("account/isAccountMaster") isAccountMaster!: boolean;
	@Getter("profile/getAbility") getAbility;

	/**
	 * EMITTERS
	 */
	@Emit("all")
	handlerAll(key: string, value: any[]) {
		return { key, value };
	}

	@Emit("focus")
	handlerFocus(key: string) {
		return { key };
	}

	/**
	 * DATA
	 */
	public rulesEndDate: any[] = [];

	/**
	 * Computed
	 */
	public get modelAccount() {
		return this.getStoreReport.filters.account[0] || null;
	}

	public set modelAccount(value) {
		this.getStoreReport.filters.account = isNull(value) ? [] : [value];
	}

	public get isCustom() {
		return this.getStoreReport.isCustom();
	}

	public get isPreviusYear() {
		return this.getStoreReport.isPreviusYear();
	}

	public get isBilling() {
		return this.getStoreReport.isBilling();
	}

	public get getCurrentDateFormatted() {
		return this.$moment().format(DEFAULT_DATE_TIME_FORMAT);
	}

	public get getRangeFiltered() {
		let dataRange = this.getStoreResources.data_range;
		if (!this.isPreviusYear) return dataRange;
		return this.getStoreResources.data_range.filter(
			(d) => d.id === DATA_RANGE_CUSTOM
		);
	}

	public get getRules() {
		return {
			isRequired,
			isAfterCompare,
			isMaxDays,
		};
	}

	public get getPermission(){
		return getPermisionReport();
	}

	public get canAccountSP(){
		return this.getAbility.can(
			this.getPermission.actions["index_account_sp"], 
			this.getPermission.subject
		);
	}

	public get canDataRangeSP(){
		return this.getAbility.can(
			this.getPermission.actions["index_data_sp"], 
			this.getPermission.subject
		);
	}

	public get canFiltersSP(){
		return this.getAbility.can(
			this.getPermission.actions["show_filter_sp"], 
			this.getPermission.subject
		);
	}

	public get getMinDate() {
		return getFormattedDate(
			this.getStoreReport.report_year,
			false
		);
	}

	public get getMaxDate() {
		return getFormattedDate(
			this.getStoreReport.report_year,
			true
		);
	}

	/**
	 * Actions
	 */

	public getError(index: "data_range" | "start_date" | "end_date") {
		return getError(this.getErrors, index);
	}

	private getErrors() {
		return this.$store.state.proccess.errors;
	}

	public handleFocus(key: string) {
		this.handlerFocus(key);
	}

	public handleRemove(event: any) {
		const { type, item } = event;
		this.getStoreReport.removeFilter(type, item.key);
	}

	public handleSelectedAll(key: string, value: Array<any>) {
		this.handlerAll(key, value);
	}

	/**
	 * Actions Handlers
	 */

	/** Life Cicle */
	public async mounted() {}

	public created() {
		this.$nextTick(async () => {});
	}

	/**
	 * WATCHERS
	 */
	@Watch("getStoreReport.end_date")
	async onRulesEndDate(val: boolean | null, old: boolean | null) {
		if (this.getStoreReport.end_date) {
			this.rulesEndDate = [
				this.getRules.isAfterCompare(
					this.getStoreReport.end_date,
					this.getStoreReport.start_date
				),
			];
		} else {
			this.rulesEndDate = [];
		}
	}
}
